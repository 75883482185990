.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 20px;
}
.navbar img{
    width: 45px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    margin-left: 2px;
}
.nav-menu{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 60px;
    font-size: 20px;
}
.nav-connect{
    padding: 10px 15px;
    border-radius: 50px;
    background: linear-gradient(267deg,#DA7C25 0.36%,#B923E1 102.36%);
    font-size: 18px;
    cursor: pointer;
    transition: 0.5s;
}
.nav-connect:hover{
    transform: scale(1.05);
}
.nav-menu li{
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;.navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 170px;
    }
    
    .navbar img {
        width: 75px;
        height: 75px;
    }
    
    .nav-menu {
        display: flex;
        align-items: center;
        list-style: none;
        gap: 60px;
        font-size: 20px;
    }
    
    .nav-connect {
        padding: 20px 40px;
        border-radius: 50px;
        background: linear-gradient(267deg, #DA7C25 0.36%, #B923E1 102.36%);
        font-size: 22px;
        cursor: pointer;
        transition: transform 0.5s;
    }
    
    .nav-connect:hover {
        transform: scale(1.05);
    }
    
    .nav-menu li {
        display: flex;
        flex-direction: column;
        gap: 5px;
        cursor: pointer;
    }
    
    .anchor-link {
        text-decoration: none;
        color: white;
    }
    
    .nav-mob-close {
        display: none;
    }
    
    .nav-mob-open {
        display: none;
    }
    
    /* Mobile Styles */
    @media (max-width: 768px) {
        .navbar {
            margin: 20px 50px;
        }
    
        .nav-connect {
            display: none;
        }
    
        .nav-mob-open {
            display: block;
            position: fixed;
            right: 30px;
            top: 30px;
            cursor: pointer;
            z-index: 3;
        }
    
        .nav-mob-close {
            display: block;
            position: absolute;
            top: 30px;
            right: 30px;
            width: 30px;
            cursor: pointer;
            z-index: 3;
        }
    
        .nav-menu {
            position: fixed;
            flex-direction: column;
            align-items: flex-start;
            top: 0;
            gap: 30px;
            background-color: #1F0016;
            width: 350px;
            height: 100vh;
            z-index: 2;
            transition: right 0.5s ease;
            right: -350px;
            padding-top: 60px;
            padding-left: 20px;
        }
    
        .nav-menu li {
            font-size: 30px;
            flex-direction: row;
            gap: 20px;
            padding-left: 20px;
        }
    
        .nav-menu.open {
            right: 0;
        }
    
        .anchor-link {
            color: white;
            font-size: 28px;
        }
    }
    
}
.anchor-link{
    text-decoration: none;
    color: white;
}
.nav-mob-close{
    display: none;
}
.nav-mob-open{
    display: none;
}
@media(max-width:768px){
    .navbar{
        margin: 20px 50px;
    }
    .nav-connect{
        display: none;
    }
    .nav-mob-open{
        display: block;
        position: fixed;
        right: 30px;
    }
    .nav-mob-close{
        display: block;
        position: relative;
        top: 30px;
        left: 290px;
        width: 30px;
    }
    .nav-menu{
        position: fixed;
        flex-direction: column;
        align-items: start;
        top: 0;
        gap: 30px;
        background-color: #1F0016;
        width: 350px;
        height: 100%;
        z-index: 2;
        transition: right 0.5s;
        right: -350px;
    }
    .nav-menu li{
        font-size: 30px;
        flex-direction: row;
        padding-left: 100px;
        gap: 20px;
    }
}
