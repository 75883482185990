.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    margin: 10px 10px;
}

.services-title h1 {
    padding: 0 20px;
    font-size: clamp(40px, 8vw, 80px);
    font-weight: 600;
}
.services-title h1:hover {
    transform: scale(1.2);
    background: linear-gradient(267deg, #DF8908 50%, #B415FF 102.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services-format {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 40px;
    border-radius: 10px;
    border: 2px solid white;
    transition: 0.4s;
    cursor: pointer;
}

.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
}

.services-format h3 {
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 600;
}

.services-format h2 {
    font-size: clamp(28px, 5vw, 38px);
    font-weight: 800;
    background: linear-gradient(267deg, #DF8908 50%, #B415FF 102.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services-format p {
    color: #d4d4d4;
    font-size: clamp(16px, 2vw, 24px);
    line-height: 1.5;
    max-width: 300px;
}

.services-readmore {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px;
}

.services-format:hover {
    border: 2px solid #ff00ff;
    background-image: linear-gradient(45deg, #3f0028, #582300);
    transform: scale(1.05);
    transition: 0.4s;
}

@media (max-width: 768px) {
    .services {
        align-items: start;
        gap: 50px;
        margin: 20px;
    }

    .services-title h1 {
        font-size: 50px;
    }

    .services-title img {
        width: 130px;
        margin-right: -20px;
    }

    .services-format {
        padding: 32px;
    }

    .services-format h2 {
        font-size: 28px;
    }

    .services-format p {
        font-size: 18px;
        line-height: 32px;
        max-width: 300px;
    }

    .services-container {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .services-container {
        grid-template-columns: 1fr;
    }

    .services-format {
        padding: 20px;
    }

    .services-title h1 {
        font-size: 40px;
    }

    .services-format h2 {
        font-size: 24px;
    }

    .services-format p {
        font-size: 16px;
    }
}
