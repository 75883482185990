.mywork {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    margin: 10px 10px;
}

.mywork-title h1 {
    padding: 0 20px;
    font-size: clamp(40px, 8vw, 80px);
    font-weight: 600;
}

.mywork-title h1:hover {
    transform: scale(1.2);
    background: linear-gradient(267deg, #DF8908 50%, #B415FF 102.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.mywork-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: auto;
    height: auto;
    max-width: 150px;
}

.mywork-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
}

.mywork-container img {
    width: 100%;
    height: auto;
    transition: 0.3s;
    cursor: pointer;
}

.mywork-container img:hover {
    transform: scale(1.10);
    border: 4px solid #ff00ff;
    transition: 0.3s;
}

.mywork-showmore {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: 50px;
    border: 2px solid white;
    padding: 15px 45px;
    font-size: clamp(16px, 2.5vw, 22px);
    font-weight: 500;
    margin-bottom: 10px;
    transition: 0.6s;
    cursor: pointer;
}

.mywork-showmore:hover {
    gap: 30px;
    transition: 0.6s;
}

@media (max-width: 768px) {
    .mywork {
        gap: 50px;
        margin: 50px 30px;
        align-items: start;
    }

    .mywork-title h1 {
        padding: 0;
        font-size: 50px;
    }

    .mywork-title img {
        width: 130px;
    }

    .mywork-container {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    .mywork-container img {
        width: 100%;
        height: auto;
    }

    .mywork-showmore {
        padding: 20px 40px;
        font-size: 18px;
        margin: 50px auto;
    }
}

@media (max-width: 480px) {
    .mywork-container {
        grid-template-columns: 1fr;
    }

    .mywork-showmore {
        padding: 15px 30px;
        font-size: 16px;
    }
}
