.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 170px;
}

.contact-title {
    position: relative;
}

.contact-title h1 {
    padding: 0 30px;
    font-size: clamp(40px, 8vw, 80px);
    font-weight: 600;
}

.contact-title h1:hover {
    transform: scale(1.2);
    background: linear-gradient(267deg, #DF8908 50%, #B415FF 102.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    max-width: 150px;
}

.contact-section {
    display: flex;
    gap: 150px;
}

.contact-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-left h1 {
    font-size: clamp(40px, 8vw, 80px);
    font-weight: 700;
    background: linear-gradient(270deg, #DF8908 80%, #B415FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-left p {
    max-width: 550px;
    color: #d8d8d8;
    font-size: 20px;
    line-height: 35px;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #d8d8d8;
    font-size: 22px;
}

.contact-detail {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contact-right {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
    width: 100%;
}

.contact-right label {
    color: #d8d8d8;
    font-size: 22px;
    font-weight: 500;
}

.contact-right input, .contact-right textarea {
    border: none;
    width: 100%;
    border-radius: 4px;
    background-color: #32323c;
    color: #a0a0a0;
    padding-left: 20px;
    font-family: 'outfit';
    font-size: 20px;
}

.contact-right textarea {
    padding: 25px;
    resize: none;
    height: 150px;
}

.contact-submit {
    border: none;
    color: white;
    border-radius: 50px;
    background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 106.28%);
    font-size: 18px;
    padding: 15px 45px;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 0.3s ease;
}

.contact-submit:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .contact {
        gap: 50px;
        margin: 50px 20px;
        align-items: start;
    }

    .contact-title h1 {
        padding: 0;
        font-size: 50px;
        font-weight: 600;
    }

    .contact-title img {
        width: 130px;
    }

    .contact-section {
        flex-direction: column;
        gap: 40px;
    }

    .contact-left h1 {
        font-size: 54px;
    }

    .contact-left p {
        font-size: 18px;
    }

    .contact-right input, .contact-right textarea {
        font-size: 18px;
    }

    .contact-submit {
        font-size: 20px;
        padding: 15px 40px;
    }
}

@media (max-width: 480px) {
    .contact-left p {
        font-size: 16px;
        line-height: 28px;
    }

    .contact-right input, .contact-right textarea {
        font-size: 16px;
    }
}
