.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 40px 30px;
}

.about-title h1 {
  font-size: clamp(30px, 8vw, 60px);
  font-weight: 600;
  text-align: center;
}

.about-title h1:hover {
  transform: scale(1.2);
  background: linear-gradient(267deg, #DF8908 50%, #B415FF 102.36%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-sections {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
}

.about-left img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 200px;
  object-fit: contain;
}

.about-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-para {
  font-size: clamp(20px, 3vw, 30px);
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  font-style: italic;
}

.about-skills {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.about-skill {
  display: flex;
  gap: 30px;
  align-items: center;
}
.about-skill:hover {
  transition: 0.3s;
  transform: scale(1.02);
}

.about-skill p {
  font-size: clamp(14px, 2vw, 18px);
  font-weight: 500;
}

.about-skill hr {
  border: none;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 106.28%);
}

.about-achievements {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 40px;
}

.about-achievement h1 {
  font-size: clamp(24px, 5vw, 50px);
  font-weight: 700;
  background: linear-gradient(270deg, #DF8908 50.41%, #B415FF 90.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-achievement p {
  font-size: clamp(12px, 2vw, 18px);
  font-weight: 500;
}
.about-achievement:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .about {
    padding: 20px;
    gap: 50px;
  }

  .about-sections {
    flex-direction: column;
    gap: 40px;
  }

  .about-left img {
    width: 80%;
    max-width: 400px;
    object-fit: contain;
  }

  .about-right {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .about-sections {
    flex-direction: column;
    gap: 30px;
  }

  .about-left {
    display: none;
  }

  .about-para {
    font-size: 16px;
  }

  .about-skill p {
    font-size: 14px;
  }

  .about-achievement h1 {
    font-size: 40px;
  }

  .about-achievement p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .about {
    padding: 10px;
    gap: 20px;
  }

  .about-title h1 {
    font-size: 30px;
  }

  .about-achievement h1 {
    font-size: 30px;
  }

  .about-achievement p {
    font-size: 12px;
  }
}
