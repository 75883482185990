.main {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 35px;
    padding: 10px 20px;
}

.main img {
    margin-top: 100px;
    border-radius: 50%;
    max-width: 300px;
    width: 100%;
}
.profile-image {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.main h1 {
    text-align: center;
    width: 70%;
    font-size: clamp(30px, 7vw, 80px);
    font-weight: 600;
    margin-top: 10px;
}

.main h1 span {
    background: linear-gradient(270deg, #DF8908 10%, #B415FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main p {
    width: 60%;
    text-align: center;
    font-size: clamp(20px, 4vw, 28px);
    line-height: 40px;
    margin: 10px 0px;
}

.main-action {
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;
}

.main-connect {
    padding: 15px 35px;
    border-radius: 50px;
    background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 106.28%);
    cursor: pointer;
    transition: 0.3s ease;
}

.main-resume {
    padding: 15px 60px;
    border-radius: 50px;
    border: 2px solid #FFF;
    cursor: pointer;
    transition: 0.3s ease;
}

.main-connect:hover {
    border: 2px solid white;
}

.main-resume:hover {
    border-color: #B415FF;
}

@media(max-width: 768px) {
    .main img {
        margin-top: 120px;
        width: 260px;
    }

    .main h1 {
        margin-top: 50px;
        width: 90%;
        font-size: 50px;
    }

    .main p {
        width: 80%;
        font-size: 18px;
        line-height: 32px;
    }

    .main-action {
        gap: 16px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 50px;
    }

    .main-connect {
        padding: 20px 30px;
        border-radius: 40px;
    }

    .main-resume {
        padding: 20px 50px;
        border-radius: 40px;
    }
    .profile-image {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
}

@media(max-width: 480px) {
    .main p {
        width: 100%;
    }

    .main h1 {
        font-size: 40px;
    }
    .profile-image {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }
}
