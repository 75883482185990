.footer {
    margin: 50px 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-top-left p {
    font-size: 20px;
    max-width: 400px;
}
.footer-top-left p:hover {
    transform: scale(1.1);
    background: linear-gradient(267deg, #DF8908 50%, #B415FF 102.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-top-left img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
}

.footer-top-right {
    display: flex;
    align-items: center;
    gap: 35px;
}

.footer-email-input {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 30px;
    border-radius: 50px;
    background: #32323B;
    padding-right: 80px;
    border: 2px solid transparent;
    transition: border 0.3s ease;
}

.footer-email-input input {
    outline: none;
    border: none;
    background-color: transparent;
    color: #a0a0a0;
    font-size: 10px;
    font-family: 'outfit', sans-serif;
    width: 100%;
}

.footer-subscribe {
    font-size: 10px;
    padding: 15px 30px;
    border-radius: 50px;
    background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 106.28%);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-bottom-right {
    display: flex;
    gap: 50px;
    margin-bottom: 50px;
}

.footer-email-input:hover {
    border: 2px solid white;
}

.footer-subscribe:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .footer {
        margin: 50px 20px;
    }

    .footer-top {
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;
    }

    .footer-bottom {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 30px;
        margin-bottom: 50px;
    }

    .footer-email-input input {
        font-size: 16px;
    }

    .footer-subscribe {
        font-size: 18px;
        padding: 18px 40px;
    }
}

@media (max-width: 480px) {
    .footer-top-left p {
        font-size: 16px;
    }

    .footer-subscribe {
        font-size: 16px;
        padding: 15px 30px;
    }

    .footer-bottom-right {
        gap: 30px;
    }
}
